$fa-font-path:   "../font";

// Third Party Node Modules
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/bootstrap/dist/css/bootstrap.min';

// Import Modules
@import "utils/variables.scss";
@import "utils/mixins.scss";
@import "pages/index.scss";

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

/* Global Styles */
html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: $dark-gray;
}

h1, h2, h3, h4, h5{
  margin-bottom: 20px;
  font-weight: 300;
  line-height: normal;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

h5 {
  font-style: italic;
}

p {
  font-size: 15px;
  padding-bottom: 15px;
}

.vertical-center {
  @include vertical-center;
}

.center-line {
  width: 100px;
  height: 1px;
  margin: 30px auto;
  background-color: $primary-color;
}

/* Default Bootstrap Overrides */

/* Nav Overrides */
.navbar {
  @include transition-in;
  @include box-shadow;

  background-color: white;
  border-bottom: $keyline;
  opacity: 0.9;
  border-radius: 0;
  margin-bottom: 0;
  top: -100px;
}

.top {
  top: 0;
}

.navbar-default {
  .navbar-brand{
    color: $primary-color;

    &:hover {
      color: $primary-color;
    }
  }

  .navbar-nav {
    > li {
      > a {
        color: $black;

        &:hover, &:focus {
          color: $primary-color;
        }
      }
    }

    > .active {
      > a {
        color: $primary-color;
        background-color: initial;

        &:hover, &:focus {
          color: $primary-color;
          background-color: initial;
        }
      }
    }
  }
}


/* Button Overrides */
.btn {
  padding: 14px 16px;
  border-radius: 0;
  font-size: 16px;

  i {
    font-size: 20px;
    margin-right: 3px;
  }

  &:hover, &:active {
    &:focus {
      outline: inherit;
      background-color: inherit;
      border-color: inherit;
    }
  }
}

.btn-tertiary {
  background-color: transparent;
  border-color: white;
  color: white;

  &:hover, &:active {
    background-color: rgba($primary-color, .2);
    border-color: $primary-color;
    color: white;
  }

  &:focus {
    outline: none;
    background-color: transparent;
    border-color: white;
    color: white;
  }
}

.btn-primary {
  @include btn-box-shadow;
  background-color: white;
  border-color: $primary-color;
  color: $black;

  &:hover, &:active, &:focus {
    outline: none;
    background-color: white;
    border-color: $primary-color;
    color: $black;
  }
}

 @media (max-width: 1200px) {
   .container-fluid {
     width: 970px;
   }
}

@media (max-width: 991px) {
  .container-fluid {
    width: auto;
  }
}
