.messages-landing-page {
  height: 100%;

  section {
    padding: 70px 0;
  }

  .section-background {
    background-color: $background-color;
    border-top: 1px solid $keyline;
    border-bottom: 1px solid $keyline;
  }

  .download-btn {
    width: 240px;
    margin: 5px 0;
  }

  .social-icons {
    padding-top: 10px;
    font-size: 26px;

    a {
      color: $primary-color;
    }
  }

  .screenshot-image {
    visibility: hidden;

    img {
      @include box-shadow(.3);
      width: 100%;
      margin: 10px 0;
    }

    &.compressed {
      img {
        max-height: 805px;
        width: auto;
      }
    }

    &.full {
      display: none;
    }
  }

  .mac-image {
    border-radius: 7px;
  }

  .call-to-action {
    padding-top: 30px;
  }

  /* Section Styling */

  .hero-wrapper {
    height: 100%;
    width: 100%;
    min-height: 100%;
    position: relative;
    background-color: $primary-color;
    background-size: cover;

    .hero-overlay {
      @include vertical-center;
      height: 100%;
      width: 100%;
      box-shadow: inset 0 0 100px rgba(0, 0, 0, .1);

    }

    .hero-content {
      width: 100%;
      color: white;

      a {
        color: $alt-link-color;

        &:hover,
        &:focus {
          color: $alt-link-color;
        }

        &.btn-primary {
          color: $black;

        }
      }
    }

    .hero-footer {
      padding-top: 30px;

      .fa-github {
        margin-left: 3px;
        font-size: 20px;
      }
    }
  }

  .home-section {
    padding: 0;
    height: 100%;
  }


  .platforms-section {
    border-top: 1px solid $primary-color;

    .platforms-header {
      text-align: center;
      padding: 0 30px 30px;
    }
  }

  .features-section {
    .features-left-column {
      text-align: left;
    }

    .features-header {
      text-align: center;
      padding: 0 30px 14px;
    }

    .features-item {
      padding: 5px 0;
      text-align: center;

      h4 {
        color: $primary-color;
      }
    }

    .notification-images {
      text-align: center;
      margin: 10px 0 30px
    }
  }

  .screenshot-image,
  .notification-images img {
     // initially set to hidden, but will be revealed through scrollreveal
     visibility: hidden;
  }

  .download-section {
    background: url("images/backgrounds/footer_lodyas.png") repeat;
    color: white;
    text-align: center;
  }

  .how-it-works-section {
    text-align: center;
  }

  .footer-section {
    padding-top: 20px;
    padding-bottom: 0;
    color: $black;
    border-top: 1px solid $keyline;
    text-align: center;
  }

  @media (max-height: 530px) {
    .hero-wrapper {
      #hero-fullscreen-image {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    .hero-wrapper {
      #hero-fullscreen-image {
        display: none;
      }

      .hero-content {
        text-align: center;
      }

      .hero-text {
        padding-top: 0;
      }
    }

    .features-section {
      .features-item {
        text-align: center;
      }
    }

    .vertical-center {
      display: block;
    }

    .screenshot-image {
      &.compressed {
        display: none;
      }

      &.full {
        display: block;
        margin: 0 10px;
      }
    }
  }
}
