@mixin box-shadow ($weight: 0.1) {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, $weight);
  box-shadow: 0 0 10px rgba(0, 0, 0, $weight);
}

@mixin btn-box-shadow {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

@mixin transition-in ($speed: .7s) {
  -webkit-transition: all $speed;
  -moz-transition: all $speed;
  transition: all $speed;
}

@mixin vertical-center {
  display: flex;
  align-items: center;
}

@mixin animation ($speed: 1.2s) {
  -webkit-animation-duration: $speed;
  animation-duration: $speed;
  -webkit-animation-fill-mode: forward;
  animation-fill-mode: forward;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.animated {
  @include animation;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
